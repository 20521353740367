import { ensureJSContent } from './legacy'

export function fetchMealTypes(t) {
  return [
    {
      label: t('MealType::Aperitif'),
      value: 'Aperitif',
    },
    {
      label: t('MealType::Breakfast'),
      value: 'Breakfast',
    },
    {
      label: t('MealType::Brunch'),
      value: 'Brunch',
    },
    {
      label: t('MealType::Cooking classes'),
      value: 'Cooking classes',
    },
    {
      label: t('MealType::Dinner'),
      value: 'Dinner',
    },
    {
      label: t('MealType::Food walk'),
      value: 'Food walk',
    },
    {
      label: t('MealType::Lunch'),
      value: 'Lunch',
    },
    {
      label: t('MealType::Tasting'),
      value: 'Tasting',
    },
    {
      label: t('MealType::Tea time'),
      value: 'Tea time',
    },
    {
      label: t('MealType::Picnic'),
      value: 'Picnic',
    },
  ]
}

export const getEventDescriptionText = ({ descriptions = [], langId = 0 }) => {
  const eventDescription = ensureJSContent(descriptions).find(
    (d) => d.describes === 'event' && (!langId || d.language_id === langId),
  )
  return eventDescription?.items?.[0]?.body?.trim()
}

export const MAX_AMENITIES_ITEMS = 8

export const MAX_AMENITIES_MOBILE_ITEMS = 3

export function getTruncatedTitle(eventTitle, maxLength) {
  const brandName = ' - Eatwith'
  const maxTitleTagLength = maxLength - brandName.length
  const titleWithBrandName = `${eventTitle}${brandName}`
  if (titleWithBrandName.length <= maxLength) return titleWithBrandName

  const words = eventTitle.split(' ')
  // we need a defined starting value, otherwise the first concatenation causes a " " at the beginning of the sentence
  let concatenation = words.shift()
  let index = 0

  let isTitleSizeValid = true
  while (isTitleSizeValid) {
    const nextConcatenation = concatenation.concat(' ', words[index])
    if (index >= words.length || nextConcatenation.length > maxTitleTagLength) {
      isTitleSizeValid = false
      continue
    }
    concatenation = nextConcatenation
    index++
  }

  return concatenation + brandName
}

export function getFromOverrides(event, field) {
  return event.overrides?.[field] ?? event[field]
}
