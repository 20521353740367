import {
  IconCheers,
  IconBreakfast,
  IconBrunch,
  IconDinner,
  IconLunch,
  IconTasting,
  IconPicnic,
  IconCooking,
  IconFoodTour,
  IconTeaTime,
} from '@vizeat/components/es6/assets/icons'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

export function useTranslatedMealtypes(type) {
  const { t } = useTranslation()

  const list = useMemo(() => {
    return [
      {
        title: 'Aperitif',
        translation: t('MealType::Aperitif'),
        picto: IconCheers,
      },
      {
        title: 'Breakfast',
        translation: t('MealType::Breakfast'),
        picto: IconBreakfast,
      },
      {
        title: 'Brunch',
        translation: t('MealType::Brunch'),
        picto: IconBrunch,
      },
      {
        title: 'Dinner',
        translation: t('MealType::Dinner'),
        picto: IconDinner,
      },
      {
        title: 'Lunch',
        translation: t('MealType::Lunch'),
        picto: IconLunch,
      },
      {
        title: 'Tasting',
        translation: t('MealType::Tasting'),
        picto: IconTasting,
      },
      {
        title: 'Picnic',
        translation: t('MealType::Picnic'),
        picto: IconPicnic,
      },
      {
        title: 'Cooking classes',
        translation: t('MealType::Cooking classes'),
        picto: IconCooking,
      },
      {
        title: 'Food walk',
        translation: t('MealType::Food walk'),
        picto: IconFoodTour,
      },
      {
        title: 'Tea time',
        translation: t('MealType::Tea time'),
        picto: IconTeaTime,
      },
    ]
  }, [t])

  if (typeof type !== 'undefined') return list.find(({ title }) => title === type)?.translation || type
  return list
}
