// libs
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
// components
import { Box } from '@vizeat/components/es6/components/Box'
import { Image } from '@vizeat/components/es6/components/Image'
// helpers
import { isVideo } from 'helpers/files'
import { getCarouselImage } from 'helpers/ImageManager'
import { roundToTheUpperDecade } from 'helpers/number'
import { useTheme } from 'styled-components'
import { CarouselRtlSensitive } from './CarouselRtlSensitive'

InlineCarousel.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  files: PropTypes.immutable.list.isRequired,
  small: PropTypes.bool,
}

InlineCarousel.defaultProps = {
  small: false,
}

export function InlineCarousel({ files, onItemClick, small }) {
  const { breakpoints } = useTheme()
  const GOLDEN_RATIO = 1 / 1.618

  const [windowWidth, setWindowWidth] = useState(1024)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    function resize() {
      setWindowWidth(window.innerWidth)
    }

    addEventListener('resize', resize)
    return function cleanUp() {
      removeEventListener('resize', resize)
    }
  }, [setWindowWidth])

  if (!files || files.size <= 0) return null
  let viewWidth
  let viewHeight

  if (small) {
    viewHeight = 250
    viewWidth = Math.ceil(viewHeight / GOLDEN_RATIO)
  } else {
    if (windowWidth < parseInt(breakpoints.tablet, 10)) viewWidth = windowWidth
    else viewWidth = Math.floor(windowWidth / 2)
    viewHeight = Math.floor(viewWidth * GOLDEN_RATIO)
  }

  return (
    <Box width='100%' minHeight={`${viewHeight}px`}>
      <CarouselRtlSensitive
        loop
        align='start'
        slideWidth={`${viewWidth}px`}
        cellSpacing={0}
        onSlideChange={({ currentSlide }) => {
          setCurrentSlide(currentSlide)
        }}
      >
        {files
          .filter((file) => !isVideo(file.path))
          .map((file, i) => (
            <Image
              key={file.id}
              src={getCarouselImage(
                file,
                true,
                `${roundToTheUpperDecade(viewWidth)}x${roundToTheUpperDecade(viewHeight)}`,
              )}
              onClick={() => {
                onItemClick(i)
              }}
              width={viewWidth}
              height={viewHeight}
              eager={i >= files.size - 2 || i - currentSlide < windowWidth / viewWidth + 1}
              style={{ cursor: 'pointer' }}
            />
          ))}
      </CarouselRtlSensitive>
    </Box>
  )
}
