import React from 'react'
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { Badge } from '@vizeat/components/es6/components/Badge'
import { Text } from '@vizeat/components/es6/components/Text'
import PropTypes from 'helpers/proptypes'
import { styledSystemPropTypes } from '@vizeat/components/es6/helpers/styled-components'

export const indicatorPropTypes = {
  tooltipStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  tooltipContentStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  tooltipPosition: PropTypes.oneOf(['top', 'bottom']),
  whiteSpace: PropTypes.string,
  isCompact: PropTypes.bool,
}

export const indicatorDefaultProps = {
  tooltipStyles: undefined,
  tooltipContentStyles: undefined,
  tooltipPosition: 'top',
  whiteSpace: undefined,
  isCompact: false,
}

export function Indicator({
  whiteSpace,
  tooltipStyles,
  tooltipContentStyles,
  tooltipContent,
  tooltipPosition,
  isCompact,
  content,
  icon,
  ...styledSystemProps
}) {
  const hasTooltipContent = tooltipContent?.trim().length > 0

  return (
    <Tooltip
      parentStyles={tooltipStyles}
      contentStyles={tooltipContentStyles}
      position={tooltipPosition}
      alignLeft
      renderContent={() =>
        hasTooltipContent && (
          <Text as='span' size='s' color='white'>
            {tooltipContent}
          </Text>
        )
      }
    >
      <Badge gridGap='4px' css={whiteSpace ? `white-space: ${whiteSpace}` : null} {...styledSystemProps}>
        {icon}
        {!isCompact && <>{content}</>}
      </Badge>
    </Tooltip>
  )
}

Indicator.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node.isRequired,
  tooltipContent: PropTypes.string,
  ...styledSystemPropTypes.background,
  ...styledSystemPropTypes.border,
  ...styledSystemPropTypes.color,
  ...indicatorPropTypes,
}

Indicator.defaultProps = {
  tooltipContent: undefined,
  ...indicatorDefaultProps,
}
