import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Rating, RatingWithSingleStar } from '@vizeat/components/es6/components/Rating'
import { Text } from '@vizeat/components/es6/components/Text'
import { formatRatingScale } from 'helpers/rating'
import { Box } from '@vizeat/components/es6/components/Box'
import { useUserQuery } from 'hooks/queries/user'

export function UserScore({ userId, withSingleStar, renderCount, alignItems, color, size }) {
  const { data: user } = useUserQuery(userId, undefined, {
    enabled: !!userId,
  })

  if (!user?.id) return null

  const ratingScore = Number(user.rating?.score || 0)
  const ratingCount = Number(user.rating?.count || 0)
  const formattedRating = formatRatingScale(ratingScore)

  if (ratingScore === 0 || ratingCount === 0) return null

  return (
    <Flex flexDirection='row' alignItems='center' color={color} gap='4px'>
      {withSingleStar ? (
        <RatingWithSingleStar score={ratingScore} size='m' mr='4px' />
      ) : (
        <Rating score={ratingScore} size='m' mr='4px' />
      )}

      <Flex alignItems={alignItems} gap='4px'>
        <Text as='span' type='attention' color='inherit' size={size}>
          {formattedRating}
        </Text>

        <Text as='span' size='xs' color='inherit'>
          (
          <Box
            as='span'
            css={`
              text-decoration: underline;
            `}
          >
            {typeof renderCount === 'function' ? renderCount(ratingCount) : ratingCount}
          </Box>
          )
        </Text>
      </Flex>
    </Flex>
  )
}

UserScore.propTypes = {
  userId: PropTypes.number,
  withSingleStar: PropTypes.bool,
  renderCount: PropTypes.func,
  color: PropTypes.string,
  alignItems: PropTypes.string,
  size: PropTypes.string,
}

UserScore.defaultProps = {
  userId: undefined,
  withSingleStar: false,
  renderCount: undefined,
  color: 'darkGray',
  alignItems: 'flex-end',
  size: undefined,
}
