/**
 * Return a rounding function to the given scale
 * @param {Number} scale
 * @returns {Function}
 * @example
 * > roundToTheNearestDecade = roundToTheNearestFactory(10)
 * > roundToTheNearestDecade(11)
 * 10
 * > roundToTheNearestDecade(15)
 * 20
 *
 * @example
 * > rountToTheNearestHalf = roundToTheNearestFactory(0.5)
 * > rountToTheNearestHalf(1.1)
 * 1
 * > rountToTheNearestHalf(1.4)
 * 1.5
 */
export function roundToTheNearestFactory(scale) {
  /**
   * Round a number to the nearest scale
   * @param {Number} input
   * @returns {Number}
   * @see roundToTheNearestFactory
   */
  return function roundToTheNearest(input) {
    return Math.round(input / scale) * scale
  }
}

/**
 * Return a ceiling function to the given scale
 * @param {Number} scale
 * @returns {Function}
 * @example
 * > roundToTheUpperDecade = roundToTheUpperFactory(10)
 * > roundToTheUpperDecade(11)
 * 20
 *
 * @example
 * > rountToTheUpperHalf = roundToTheUpperFactory(0.5)
 * > rountToTheUpperHalf(1.1)
 * 1.5
 */
export function roundToTheUpperFactory(scale) {
  /**
   * Ceil the number to the upper scale
   * @param {Number} input
   * @returns {Number}
   * @see roundToTheUpperFactory
   */
  return function roundToTheUpper(input) {
    return Math.ceil(input / scale) * scale
  }
}

export const roundToTheUpperDecade = roundToTheUpperFactory(10)
export const roundToTheUpperHalfDecade = roundToTheUpperFactory(5)

/**
 * Given a lower and an upper boundary as well as a value
 * the function will return the value if it is within the boundaries
 * it will return the min if the value is below
 * it will return the max if the value is above
 * @param {Number} min
 * @param {Number} max
 * @param {Number} val
 */
export function clamp(min, max, val) {
  return Math.min(Math.max(min, val), max)
}

function ceilToFiveOrZero(number) {
  return number < 5 ? number : roundToTheUpperHalfDecade(number)
}

export function ceilPrice(eurPrice, rate) {
  if (eurPrice === undefined || !rate) return
  if (rate === 1) return eurPrice
  const price = Math.ceil(eurPrice * rate)
  const digits = String(price).length

  if (digits < 3) {
    const divider = 10 ** digits
    return Math.ceil(price / divider) * divider
  } else {
    const divider = 10 ** (digits - 2)
    return ceilToFiveOrZero(Math.ceil(price / divider)) * divider
  }
}
