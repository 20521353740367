import { getPlaylist } from 'api/playlist'
import { useQueries, useQuery } from '@tanstack/react-query'
import { STALE_TIME } from 'helpers/query'

function getQuery(playlistId) {
  return {
    queryKey: ['playlist', String(playlistId)],
    queryFn: () => getPlaylist(playlistId),
    staleTime: STALE_TIME,
  }
}

export function usePlaylist(playlistId, reactQueryOptions) {
  return useQuery({
    ...getQuery(playlistId),
    ...reactQueryOptions,
  })
}

export function usePlaylists(playlistIds, reactQueryOptions) {
  return useQueries({
    queries: playlistIds.map((playlistId) => ({
      ...getQuery(playlistId),
      placeholderData: { placeholderId: playlistId },
      ...reactQueryOptions,
    })),
  })
}
