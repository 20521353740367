import { useQuery } from '@tanstack/react-query'
import { getTripAdvisorUser } from 'api/tripadvisor'
import { STALE_TIME, serializeQueryKey } from 'helpers/query'
import { useLocale } from 'hooks/locales/useLocale'
import { useCallback } from 'react'
import { create, emptyTripAdvisorUserSchema } from 'schemas/tripAdvisorUser'

function parseRankingText(user) {
  if (!user.ranking_data) return null
  const ranking = parseInt(user.ranking_data.ranking)
  const total = parseInt(user.ranking_data.ranking_out_of)
  const isTop10Percent = total && ranking / total <= 0.1
  return isTop10Percent ? user.ranking_data.ranking_string : null
}

export function useTripAdvisorUserQuery(id, query, reactQueryOptions) {
  const { locale } = useLocale()

  return useQuery({
    placeholderData: emptyTripAdvisorUserSchema,
    staleTime: STALE_TIME,
    queryKey: serializeQueryKey('tripAdvisorUser', {
      id,
      language: locale,
      ...query,
    }),
    queryFn: () => getTripAdvisorUser(id, query),
    select: useCallback((data) => {
      const user = create(data)
      return {
        user,
        topRankingText: parseRankingText(user),
      }
    }, []),
    enabled: !!id,
    ...reactQueryOptions,
  })
}
