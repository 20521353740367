// libs
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import styled, { css } from 'styled-components'
// helpers
import { getCarouselImage } from 'helpers/ImageManager'
// components
import { Box } from '@vizeat/components/es6/components/Box'
import { Image } from '@vizeat/components/es6/components/Image'
import IconCross from '@vizeat/components/es6/assets/icons/IconCross'
import { CarouselRtlSensitive } from './CarouselRtlSensitive'
import { usePreventBodyScroll } from 'hooks/usePreventBodyScroll'

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const StyledThumb = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  border-radius: ${({ theme }) => theme.radii.sm};
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.5')};
  transition: all 0.2s ease-out;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 1;
  }
`

const carouselWrapperStyles = css`
  padding-bottom: 100%;
  position: relative;

  ${({ theme }) => theme.media.tablet`
    padding-bottom: 66.7%
  `}
`

export function Gallery({ files, onClose, initialIndex, imageAltComputed }) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const size = files.size ?? files.length //! TODO files must be an array
  const nextIndex = (currentIndex + 1) % size
  const prevIndex = (currentIndex - 1 + size) % size

  usePreventBodyScroll()

  useEffect(() => {
    function handleKeyUp({ key }) {
      if (key === 'Escape') onClose()
      else if (key === 'ArrowLeft') setCurrentIndex(prevIndex)
      else if (key === 'ArrowRight') setCurrentIndex(nextIndex)
    }

    window.addEventListener('keyup', handleKeyUp)
    return function cleanUp() {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [prevIndex, nextIndex, setCurrentIndex, onClose])

  return (
    <Box position='fixed' top='0' left='0' right='0' bottom='0' width='100%' bg='black' zIndex='100000'>
      <Box
        as={(props) => <IconCross {...props} color='white' />}
        onClick={onClose}
        fontSize='36px'
        position='absolute'
        top={{ tablet: '16px', default: '18px' }}
        right={{ tablet: '16px', default: '4px' }}
        zIndex={1}
        css='cursor: pointer'
      />

      <Box
        position='relative'
        width='100%'
        height='100%'
        padding={{ default: '72px 0', phablet: '72px 24px' }}
        margin='auto'
        maxWidth='95vh'
      >
        <CarouselRtlSensitive
          align='start'
          startIndex={initialIndex}
          indexToScroll={currentIndex}
          onSlideChange={({ currentSlide }) => {
            setCurrentIndex(currentSlide)
          }}
          wrapperStyles={carouselWrapperStyles}
          containerStyles='position: absolute; top: 0; left: 0; right: 0; bottom: 0;'
          carouselStyles='height: 100%'
          slideStyles='height:0; min-height:100%;'
          loop
        >
          {files.map((file, i) => (
            <StyledImage
              key={file.id}
              src={getCarouselImage(file)}
              alt={imageAltComputed?.(file) ?? file.id}
              eager={[prevIndex, currentIndex, nextIndex].includes(i)}
              width='1024px'
              height='632px'
            />
          ))}
        </CarouselRtlSensitive>

        <Box
          position='absolute'
          top='72px'
          right={{ default: 0, phablet: '24px' }}
          color='white'
          bg='rgba(0, 0, 0, .5)'
          padding='6px'
          zIndex='9'
          fontSize='14px'
        >
          {currentIndex + 1} / {size}
        </Box>
        <CarouselRtlSensitive
          startIndex={initialIndex}
          indexToScroll={currentIndex}
          slideWidth='160px'
          slideHeight='100px'
          showArrowControls={false}
          wrapperStyles='margin-top: 16px;'
          loop
        >
          {files.map((file, i) => (
            <StyledThumb
              key={file.id}
              src={getCarouselImage(file)}
              alt={imageAltComputed?.(file) ?? file.id}
              onClick={() => {
                setCurrentIndex(i)
              }}
              isSelected={i === currentIndex}
              width='160px'
              height='100px'
            />
          ))}
        </CarouselRtlSensitive>
      </Box>
    </Box>
  )
}

Gallery.propTypes = {
  files: PropTypes.immutable.list.isRequired,
  onClose: PropTypes.func.isRequired,
  initialIndex: PropTypes.number.isRequired,
  imageAltComputed: PropTypes.func,
}

Gallery.defaultProps = {
  imageAltComputed: undefined,
}
