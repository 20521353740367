import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useCarouselContext } from '@vizeat/components/es6/components/Carousel/Carousel'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { CarouselRtlSensitive } from '../carousels'
import { gtmEventListDisplayed } from 'gtm'

function EventItem({ eventElement }) {
  const { canScroll } = useCarouselContext()
  const EventElement = React.cloneElement(eventElement, {
    showCarousel: !canScroll,
  })

  return EventElement
}

EventItem.propTypes = {
  eventElement: PropTypes.node,
}

export function EventsSection({
  background,
  children,
  containerStyles,
  events,
  footer,
  gtmType,
  id,
  isCarouselForMobile,
  render,
  subtitle,
  title,
  wrapInCarousel,
  slideWidth,
  cellSpacing,
}) {
  const { appCurrency: currency } = useCurrency()
  const isTabletOrHigher = useMediaQuery('tablet')

  useEffect(() => {
    if (events.size > 0 && gtmType) gtmEventListDisplayed({ type: gtmType, events, currency })
  }, [gtmType, events.size]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box id={id} background={background} py='32px' px='0' css={containerStyles}>
      {title && (
        <Heading type='large' as='h1'>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading type='medium' as='h2'>
          {subtitle}
        </Heading>
      )}
      {children}

      {!wrapInCarousel && (isTabletOrHigher || !isCarouselForMobile) ? (
        <Grid gap='24px 16px' templateColumns='repeat(auto-fill, minmax(260px, 1fr))'>
          {events.map(render)}
        </Grid>
      ) : (
        <CarouselRtlSensitive slideWidth={slideWidth} cellSpacing={cellSpacing} showArrowControls={wrapInCarousel}>
          {events.map((event) => (
            <EventItem key={event.id} eventElement={render(event)} />
          ))}
        </CarouselRtlSensitive>
      )}

      {footer}
    </Box>
  )
}

EventsSection.propTypes = {
  background: PropTypes.string,
  containerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.node,
  events: PropTypes.immutable.list.isRequired,
  footer: PropTypes.node,
  gtmType: PropTypes.string,
  id: PropTypes.string,
  isCarouselForMobile: PropTypes.bool,
  render: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  wrapInCarousel: PropTypes.bool,
  slideWidth: PropTypes.string,
  cellSpacing: PropTypes.number,
}

EventsSection.defaultProps = {
  background: undefined,
  containerStyles: undefined,
  children: undefined,
  footer: undefined,
  gtmType: undefined,
  id: undefined,
  isCarouselForMobile: true,
  subtitle: undefined,
  title: undefined,
  wrapInCarousel: false,
  slideWidth: '270px',
  cellSpacing: undefined,
}
