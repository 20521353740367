import React from 'react'
import { useTranslation } from 'next-i18next'
import IconCrown from '@vizeat/components/es6/assets/icons/IconCrown'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import PropTypes from 'helpers/proptypes'

export function BestSellerIndicator({
  tooltipStyles,
  tooltipContentStyles,
  tooltipPosition,
  whiteSpace,
  isCompact,
  size,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconCrown size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      backgroundColor='palePurple'
      color='purple'
      whiteSpace={whiteSpace}
      content={t('Badge::Best seller')}
      tooltipContent={t(
        "BestSeller::Indicator::One of our most popular experiences! Don't miss out - book your seat now.",
      )}
      tooltipPosition={tooltipPosition}
    />
  )
}

BestSellerIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

BestSellerIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
