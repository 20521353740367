import { Flex } from '@vizeat/components/es6/components/Flex'
import React from 'react'
import styled from 'styled-components'

export const ScrollableSlider = styled((props) => <Flex {...props} />)`
  flex-wrap: nowrap;
  overflow-x: scroll;
  transition: scroll 0.5s;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`
