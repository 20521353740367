import { ensureJSContent } from './legacy'

export const TAGS = {
  DEMO_EVENT: 146,
  BEST_SELLER: 438,
  STAR_CHEF: 437,
  MASTERCHEF: [204, 262, 251, 246, 234, 225, 214, 206, 363, 446, 559],
  ECO_HERO: 576,
  FAMILY_FRIENDLY: 268,
  FOOD_SAFETY: 519,
  TA_TRAVELERS_CHOICE: 532,
  NO_INDEX: 971,
}

export function mapTagsToBooleans(tags = []) {
  const tagIds = ensureJSContent(tags).map(({ id }) => id)
  const masterChefTagId = tagIds.find((id) => TAGS.MASTERCHEF.includes(id))

  return {
    isDemoEvent: tagIds.includes(TAGS.DEMO_EVENT),
    isBestSeller: tagIds.includes(TAGS.BEST_SELLER),
    isStarChef: tagIds.includes(TAGS.STAR_CHEF),
    masterChefSeason: masterChefTagId ? TAGS.MASTERCHEF.indexOf(masterChefTagId) : null,
    isEcoHero: tagIds.includes(TAGS.ECO_HERO),
    isFamilyFriendly: tagIds.includes(TAGS.FAMILY_FRIENDLY),
    isFoodSafety: tagIds.includes(TAGS.FOOD_SAFETY),
    isTATravelersChoice: tagIds.includes(TAGS.TA_TRAVELERS_CHOICE),
    isNoIndex: tagIds.includes(TAGS.NO_INDEX),
  }
}
