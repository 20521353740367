import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const tripAdvisorUserSchema = yup.object({
  awards: yup.array().default([]),
  description: yup.string().nullable().default(''),
  location_id: yup.number().nullable().default(null),
  location_string: yup.string().default(''),
  name: yup.string().default(''),
  num_reviews: yup.string().default(''),
  ranking_data: yup.object({}).nullable().default(null),
  rating_image_url: yup.string().nullable().default(''),
  rating: yup.string().nullable().default(''),
  reviews: yup.array().default([]),
  web_url: yup.string().default(''),
  write_review: yup.string().default(''), // URL supplied by TripAdvisor where users can submit a review
})

export function create(raw) {
  const { location_id: id, ...data } = cast(tripAdvisorUserSchema, raw)

  return {
    id,
    ...data,
  }
}

export const emptyTripAdvisorUserSchema = create()
