import React from 'react'
import { useTranslation } from 'next-i18next'
import PropTypes from 'helpers/proptypes'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import IconTripAdvisor from '@vizeat/components/es6/assets/icons/IconTripAdvisor'

export function TripAdvisorTopRatedIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  rankingText,
  tooltipPosition,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconTripAdvisor size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      color='tripAdvisorGreen'
      backgroundColor='tripAdvisorPaleGreen'
      content={t('Badge::TripAdvisor::Top ranked')}
      whiteSpace={whiteSpace}
      tooltipContent={rankingText}
      tooltipPosition={tooltipPosition}
    />
  )
}
TripAdvisorTopRatedIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
  rankingText: PropTypes.string.isRequired,
}

TripAdvisorTopRatedIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
