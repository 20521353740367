import React from 'react'
import { useTranslation } from 'next-i18next'
import IconEcoHero from '@vizeat/components/es6/assets/icons/IconEcoHero'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import PropTypes from 'helpers/proptypes'

export function EcoHeroIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  tooltipPosition,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconEcoHero size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      color='darkGreen'
      backgroundColor='paleGreen'
      content={t('Badge::EcoHero')}
      whiteSpace={whiteSpace}
      tooltipContent={t(
        'EcoHero::Indicator::This host is compliant with at least 70% of the sustainable practices based on a 12-section study. Eatwith eco-heroes are committed to promoting plant-based recipes, plastic, and waste avoidance, and fair trade.',
      )}
      tooltipPosition={tooltipPosition}
    />
  )
}

EcoHeroIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

EcoHeroIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
