import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { Text } from '@vizeat/components/es6/components/Text'
import { RatingWithSingleStar } from '@vizeat/components/es6/components/Rating'
import { ensureJSContent } from 'helpers/legacy'

export function ReviewRating({ user }) {
  const jsUser = useMemo(() => ensureJSContent(user), [user])
  const ratingScore = Number(jsUser?.rating?.score || 0)
  const ratingCount = Number(jsUser?.rating?.count || 0)

  if (!ratingScore || !ratingCount) return null

  return (
    <>
      <RatingWithSingleStar score={ratingScore} size='m' />
      <Text as='span' fontWeight='bolder'>
        {ratingScore}
      </Text>
      <Text as='span' color='lightGray'>
        ({ratingCount})
      </Text>
    </>
  )
}

ReviewRating.propTypes = {
  user: PropTypes.shape({}).isRequired,
}
