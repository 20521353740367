import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useLocale } from 'hooks/locales/useLocale'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { getEventTitle } from 'helpers/legacy'
import { EventCard } from '@vizeat/components/es6/components/EventCard'
import { SmartLink } from 'components/shared/SmartLink'
import { ReviewRating } from 'components/shared/ratings/ReviewRating'
import { scaleCropFile } from 'helpers/ImageManager'
import { gtmEventClicked } from 'gtm'
import { usePriceFormatter } from 'hooks/price'
import { useToggle } from '@vizeat/components/es6/hooks/useToggle/useToggle'
import { useEventFilesQuery } from 'hooks/queries/event'
import { useTranslatedMealtypes } from 'hooks/events/useTranslatedFilters'

export function BasicEventCard({ event, eventLink, onEventClick, gtmType, showCarousel }) {
  const { direction } = useIsRtl()
  const { locale } = useLocale()
  const { user: host, place } = event
  const translatedMealtype = useTranslatedMealtypes(event.type)

  const [isHovered, { on: setIsHovered }] = useToggle()
  const { data: files = [] } = useEventFilesQuery(event.id, undefined, {
    enabled: isHovered,
  })

  const resizedFiles = useMemo(
    () => [
      ...new Set([
        scaleCropFile({ fileId: event.cover_id, size: '350x350', smart: true }),
        ...files.map((image) => scaleCropFile({ fileId: image.id, size: '350x350', smart: true })),
      ]),
    ],
    [event.cover_id, files],
  )

  const formattedPrice = usePriceFormatter({ price: event.price })

  function handleClick(e) {
    e.stopPropagation()
    if (gtmType) gtmEventClicked({ type: gtmType, event, place })
    if (onEventClick) onEventClick()
  }

  return (
    <EventCard
      as={SmartLink}
      display='block'
      href={eventLink || `/events/${event.id}`}
      onClick={handleClick}
      onMouseEnter={setIsHovered}
      onTouchStart={setIsHovered}
    >
      <EventCard.Body showCarousel={showCarousel} images={resizedFiles} direction={direction}>
        <EventCard.Content eventCategory={translatedMealtype} eventTitle={getEventTitle(locale, event)} />
      </EventCard.Body>

      <EventCard.Footer formattedPrice={formattedPrice} rating={<ReviewRating user={host} />} />
    </EventCard>
  )
}

BasicEventCard.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    cover_id: PropTypes.number,
    id: PropTypes.number,
    price: PropTypes.number,
    user: PropTypes.shape({}),
    place: PropTypes.shape({}),
  }).isRequired,
  eventLink: PropTypes.string,
  onEventClick: PropTypes.func,
  gtmType: PropTypes.string,
  showCarousel: PropTypes.bool,
}

BasicEventCard.defaultProps = {
  eventLink: undefined,
  gtmType: undefined,
  onEventClick: undefined,
  showCarousel: true,
}
