import React from 'react'
import { useTranslation } from 'next-i18next'
import IconInfoFilled from '@vizeat/components/es6/assets/icons/IconInfoFilled'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import PropTypes from 'helpers/proptypes'

export function NewExperienceIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  tooltipPosition,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconInfoFilled size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      backgroundColor='paleBlue'
      color='turquoise'
      whiteSpace={whiteSpace}
      content={t('Badge::New experience')}
      tooltipContent={t(
        'SearchEventCard::Be one of the first people to try this experience and tell us what you think.',
      )}
      tooltipPosition={tooltipPosition}
    />
  )
}

NewExperienceIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

NewExperienceIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
