import React from 'react'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { EventsSection } from 'components/shared/sections'
import { BasicSearchEventCard } from 'components/shared/cards'
import PropTypes from 'helpers/proptypes'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

export function EventsSearchSection({ variant, gtmType, searchPayload, searchOptions, slideWidth, cellSpacing }) {
  const { selectData } = useSearchQuery(searchPayload, searchOptions)
  const isTabletOrHigher = useMediaQuery('tablet')
  const isSmall = variant === 'small'

  const events = selectData(({ events }) => events)

  if (events.size === 0) return null

  return (
    <EventsSection
      slideWidth={slideWidth || (isSmall ? '185px' : undefined)}
      cellSpacing={cellSpacing || (isTabletOrHigher ? 20 : 12)}
      containerStyles='padding: 0'
      events={events}
      gtmType={gtmType}
      wrapInCarousel
      render={(event) => (
        <BasicSearchEventCard
          key={event.id}
          variant={isSmall ? 'small' : undefined}
          height={isSmall ? '167px' : undefined}
          gtmType={gtmType}
          event={event}
          hideSeatsLeft
          hideNextAvailableDate
        />
      )}
    />
  )
}

EventsSearchSection.propTypes = {
  searchPayload: PropTypes.shape({}).isRequired,
  searchOptions: PropTypes.shape({}),
  variant: PropTypes.oneOf(['small']),
  gtmType: PropTypes.string,
  slideWidth: PropTypes.string,
  cellSpacing: PropTypes.number,
}

EventsSearchSection.defaultProps = {
  variant: undefined,
  searchOptions: undefined,
  gtmType: undefined,
  slideWidth: undefined,
  cellSpacing: undefined,
}
