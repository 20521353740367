import React from 'react'
import { useTranslation } from 'next-i18next'
import IconCertification from '@vizeat/components/es6/assets/icons/IconCertification'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import PropTypes from 'helpers/proptypes'

export function FoodSafetyCertificationIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  tooltipPosition,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconCertification size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      backgroundColor='paleBlue'
      color='blue'
      whiteSpace={whiteSpace}
      content={t('Badge::Food Safety Certified')}
      tooltipContent={t(
        "FoodSafetyCertification::Indicator::According to Eatwith's policy, this host owns a food safety certification delivered by official organisms.",
      )}
      tooltipPosition={tooltipPosition}
    />
  )
}

FoodSafetyCertificationIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

FoodSafetyCertificationIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
