import React from 'react'
import { useTranslation } from 'next-i18next'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import IconStarsFilled from '@vizeat/components/es6/assets/icons/IconStarsFilled'
import PropTypes from 'helpers/proptypes'

export function StarChefIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  tooltipPosition,
}) {
  const { t } = useTranslation()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconStarsFilled size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      backgroundColor='paleYellow'
      color='yellow'
      content={t('Badge::Star chef')}
      whiteSpace={whiteSpace}
      tooltipContent={t("StarChef::Indicator::This host is a professional chef. You'll love their fine dining dishes!")}
      tooltipPosition={tooltipPosition}
    />
  )
}

StarChefIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

StarChefIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
