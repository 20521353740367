import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { gtmButtonClicked } from 'gtm'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Collapse, AnimatedChevron } from '@vizeat/components/es6/components/Collapse'
import { Text } from '@vizeat/components/es6/components/Text'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { MobileModal, MobileModalTitle } from '@vizeat/components/es6/components/MobileModal'

const Wrapper = styled(Box)`
  position: relative;
`

export function CollapsibleSection({
  triggerMessage,
  TriggerIcon,
  buttonId,
  iconColor,
  borderColor,
  background,
  titleColor,
  children,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const calledOnce = useRef(false)
  const isTabletOrHigher = useMediaQuery('tablet')

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev)
    if (calledOnce.current) return

    gtmButtonClicked({
      buttonId,
      buttonCta: triggerMessage,
    })

    calledOnce.current = true
  }, [buttonId, triggerMessage])

  return (
    <Wrapper border='sm' borderColor={borderColor} bg={background} borderRadius='lg' mb='24px'>
      <Flex
        alignItems='center'
        justify='space-between'
        p='12px'
        onClick={handleToggle}
        css={`
          cursor: pointer;
        `}
      >
        <Flex flexDirection='row' alignItems='center'>
          {TriggerIcon}
          <Text as='span' size='s' color={titleColor}>
            {triggerMessage}
          </Text>
        </Flex>
        <AnimatedChevron expanded={isExpanded} color={iconColor} size='16px' />
      </Flex>

      {isTabletOrHigher ? (
        <Collapse isExpanded={isExpanded}>
          <Box p='0 8px 12px'>{children}</Box>
        </Collapse>
      ) : (
        <MobileModal
          show={isExpanded}
          onHide={handleToggle}
          Header={
            <>
              {TriggerIcon}
              <MobileModalTitle content={triggerMessage} />
            </>
          }
        >
          {children}
        </MobileModal>
      )}
    </Wrapper>
  )
}

CollapsibleSection.propTypes = {
  triggerMessage: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  TriggerIcon: PropTypes.node,
  iconColor: PropTypes.string,
  borderColor: PropTypes.string,
  background: PropTypes.string,
  titleColor: PropTypes.string,
}

CollapsibleSection.defaultProps = {
  TriggerIcon: null,
  iconColor: 'black',
  borderColor: 'lightGray',
  background: undefined,
  titleColor: undefined,
}
