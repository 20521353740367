import settings from 'settings'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import styled, { useTheme } from 'styled-components'
import Image from 'next/image'
import { scaleCropFile } from 'helpers/ImageManager'

const StyledImage = styled(Image)``

const GOLDEN_RATIO = 1.618 // (🙏 divine proportion)

function useOptimizationProps({ src, aspectRatio = GOLDEN_RATIO, fill, smart, width, height }) {
  const { breakpoints } = useTheme()
  const isSrcFromImageDeliveryPlatform = !isNaN(src)

  const getImageURL = useCallback(
    (size, quality) =>
      scaleCropFile({
        fileId: src,
        size,
        smart,
        quality,
        format: 'webp',
      }),
    [smart, src],
  )

  const loader = useCallback(
    ({ width: responsiveWidth, quality }) => {
      if (fill) {
        const height = Math.ceil(responsiveWidth / aspectRatio)
        return getImageURL(`${responsiveWidth}x${height}`, quality)
      }

      const ratio = responsiveWidth / width
      return getImageURL(`${responsiveWidth}x${Math.ceil(height * ratio)}`, quality)
    },
    [aspectRatio, fill, getImageURL, height, width],
  )

  const optimizedSource = useMemo(() => {
    if (!isSrcFromImageDeliveryPlatform) return src

    const maxWidth = width || parseInt(breakpoints.slice(-1), 10)
    const maxHeight = height || Math.ceil(maxWidth / aspectRatio)
    return getImageURL(`${maxWidth}x${maxHeight}`)
  }, [aspectRatio, breakpoints, getImageURL, height, isSrcFromImageDeliveryPlatform, src, width])

  return {
    src: optimizedSource,
    loader: isSrcFromImageDeliveryPlatform ? loader : undefined,
    unoptimized: settings.vercelEnv !== 'production' || (!!width && !!height),
  }
}

export function NextImage({
  alt,
  aspectRatio,
  blurDataURL,
  fill,
  height,
  imageStyle,
  placeholder,
  priority,
  smart,
  src,
  width,
}) {
  const loaderProps = useOptimizationProps({ src, aspectRatio, fill, smart, width, height })

  return (
    <StyledImage
      blurDataURL={blurDataURL}
      layout={fill ? 'fill' : 'intrinsic'}
      placeholder={placeholder}
      priority={priority}
      src={src}
      alt={alt}
      height={height}
      width={width}
      css={imageStyle}
      {...loaderProps}
    />
  )
}

NextImage.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  blurDataURL: PropTypes.string,
  height: PropTypes.number,
  imageStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  ]),
  fill: PropTypes.bool,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  smart: PropTypes.bool,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.number,
}

NextImage.defaultProps = {
  alt: '',
  aspectRatio: undefined,
  blurDataURL: undefined,
  height: undefined,
  imageStyle: undefined,
  fill: false,
  placeholder: undefined,
  priority: undefined,
  smart: false,
  width: undefined,
}
