// libs
import React from 'react'
import PropTypes from 'helpers/proptypes' // components
import { IntersectionObserver } from '@vizeat/components/es6/components/IntersectionObserver'
import { Box } from '@vizeat/components/es6/components/Box'
// helpers
import { getCoordinatesFromPlace } from 'helpers/legacy'
import dynamic from 'next/dynamic'
import { urlFactory } from 'helpers/url'

const Map = dynamic(() => import('@vizeat/components/es6/components/Map').then((mod) => mod.Map), {
  loading: () => <div>Loading...</div>,
  ssr: false,
})
const CircleMarker = dynamic(() => import('@vizeat/components/es6/components/Map').then((mod) => mod.CircleMarker), {
  ssr: false,
})

export function MapDetailsSection({ place }) {
  const coordinates = getCoordinatesFromPlace(place)

  return (
    <Box pb='24px'>
      {coordinates.lat && coordinates.lng && (
        <IntersectionObserver onlyOnce>
          {({ isIntersecting }) => (
            <Box height='288px'>
              {isIntersecting && (
                // zoom can vary between 0 (far out) and 24 (close in)
                // we want to prevent zooming in too close, so we limit it to 15
                // we default to 14 as this level provide some local context like transportation and close by POI
                <Map
                  mapViewport={{ latitude: coordinates.lat, longitude: coordinates.lng, zoom: 14 }}
                  maxZoom={15}
                  mapStyle={`${urlFactory.cfw.proxy.stadiamaps()}/styles/outdoors.json`}
                >
                  {/* to ensure the circle size remain somewhat consistent across zoom levels */
                  /* we use a function ot the zoom level to calculate the size of the circle */
                  /* the closer you get (higher zoom level) the bigger the circle            */}
                  {(mapState) => (
                    <CircleMarker latitude={coordinates.lat} longitude={coordinates.lng} size={mapState.zoom ** 2} />
                  )}
                </Map>
              )}
            </Box>
          )}
        </IntersectionObserver>
      )}
    </Box>
  )
}

MapDetailsSection.propTypes = {
  place: PropTypes.shape({}).isRequired,
}
