import { getFromOverrides } from 'helpers/events'
import { usePriceFormatter } from './usePriceFormatter'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCurrencyContext } from 'hooks/currencies/useCurrencyContext'

export function usePriceResize() {
  const [shouldResize, setShouldResize] = useState(false)
  const elementRef = useRef(null)
  const { appCurrencyIso3 } = useCurrencyContext()

  useEffect(() => {
    const contentSize = elementRef.current?.innerText?.replace(/\s+/g, '').length || 0
    setShouldResize(contentSize > 9)
  }, [appCurrencyIso3])

  return { shouldResize, elementRef }
}

export function useDiscount(event) {
  const originalPrice = event.price
  const price = useMemo(() => getFromOverrides(event, 'price'), [event])
  const discount = Math.max(originalPrice - price, 0)
  const discountPercentage = Math.floor((discount / originalPrice) * 100)
  const shouldDisplayDiscount = discountPercentage > 5
  const formattedPrice = usePriceFormatter({ price: originalPrice })

  return useMemo(
    () => ({
      shouldDisplayDiscount,
      discountPercentage,
      formattedPrice,
    }),
    [discountPercentage, formattedPrice, shouldDisplayDiscount],
  )
}
