import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useLocale } from 'hooks/locales/useLocale'
import { numbers } from '@vizeat/helpers'

const { formatIntegerPrice, formatPriceAsFloat, formatPriceAsInt } = numbers

export function useMakePriceFormatter() {
  const { t } = useTranslation()
  const { appCurrency } = useCurrency()
  const { locale } = useLocale()

  return useCallback(
    ({ price, currency = appCurrency, showCents = false } = {}) => {
      if (price === undefined) return null
      if (price === 0) return t('Price::Free')
      if (showCents) return formatIntegerPrice(Math.ceil(price), locale, currency)
      const priceAsFloat = formatPriceAsFloat(price, currency)
      const roundedPriceAsInt = formatPriceAsInt(Math.round(priceAsFloat), currency)
      return formatIntegerPrice(Math.ceil(roundedPriceAsInt), locale, currency)
    },
    [appCurrency, locale, t],
  )
}

export function usePriceFormatter({ price, currency, showCents = false } = {}) {
  const priceFormatter = useMakePriceFormatter()
  return priceFormatter({ price, currency, showCents })
}
