/**
 * Returns a string containing formatted rating scale of the type score/maxScore where score may be a decimal and maxScore is an integer
 *
 * @param {number} score
 * @param {number} maxScore By default set to 5
 *
 * @returns {string}
 */
export function formatRatingScale(score, maxScore = 5) {
  if (!score) return ''
  return `${Number.isInteger(score) ? score : Number(score).toFixed(1)}/${maxScore}`
}
