import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { List } from 'immutable'
import { EventsSection } from '.'
import { EventCardSkeleton } from '@vizeat/components/es6/components/EventCard'
import { BasicSearchEventCard } from '../cards'
import { ScrollableSlider } from '../ScrollableSlider'
import { Box } from '@vizeat/components/es6/components/Box'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'

const DEFAULT_SKELETON_NUMBER = 4

const eventsSkeletons = new List(Array.from({ length: DEFAULT_SKELETON_NUMBER }, (_, index) => ({ id: index })))

const eventsSectionProps = {
  slideWidth: '242px',
  cellSpacing: 12,
}

const StyledSearchItem = styled((props) => (
  <Box as='li' minWidth='unset' minHeight='unset' role='button' color='lightGray' {...props} />
))`
  cursor: pointer;
  white-space: nowrap;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.eatwithOrange : undefined)};

  &:after {
    content: '';
    display: block;
    margin-top: 8px;
    width: ${({ isSelected }) => (isSelected ? '100%' : '0')};
    border-top: ${({ theme }) => `${theme.borders.md} ${theme.colors.eatwithOrange}`};
    transition: width 0.3s cubic-bezier(0.22, 1, 0.36, 1); // https://easings.net/#easeOutQuint
  }

  &:hover {
    color: ${({ theme }) => theme.colors.eatwithOrange};
    transition: color 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &:after {
      width: 100%;
    }
  }
`

const filtersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.oneOf(['mealtypes', 'dateRange']),
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    label: PropTypes.string,
  }),
)

function SearchFilters({ filters, onChange }) {
  const [selectedFilter, setSelectedFilter] = useState()
  const { t } = useTranslation()

  if (filters.length === 0) return null

  function handleFilterChange(filter) {
    return function () {
      if (!filter) {
        setSelectedFilter(undefined)
        onChange(undefined)
        return
      }

      const { label, type, value } = filter
      setSelectedFilter(label)

      if (type === 'dateRange') {
        onChange(value)
      } else {
        onChange({
          [type]: value,
        })
      }
    }
  }

  return (
    <ScrollableSlider
      forwardedAs='ul'
      margin='0 0 24px'
      padding='0'
      gap={{ default: '18px', tablet: '40px' }}
      css={`
        list-style: none;
      `}
    >
      <StyledSearchItem isSelected={!selectedFilter} onClick={handleFilterChange()}>
        {t('PlaylistEventsSection::All experiences')}
      </StyledSearchItem>

      {filters.map(({ label, value, type }) => (
        <StyledSearchItem
          key={label}
          isSelected={selectedFilter === label}
          onClick={handleFilterChange({
            label,
            value,
            type,
          })}
        >
          {label}
        </StyledSearchItem>
      ))}
    </ScrollableSlider>
  )
}

SearchFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  filters: filtersPropTypes.isRequired,
}

export function PlaylistEventsSectionSkeleton({ containerStyles }) {
  return (
    <EventsSection
      containerStyles={containerStyles}
      events={eventsSkeletons}
      render={({ id }) => <EventCardSkeleton key={id} height='341px' />}
      wrapInCarousel
      {...eventsSectionProps}
    />
  )
}

PlaylistEventsSectionSkeleton.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
}

PlaylistEventsSectionSkeleton.defaultProps = {
  containerStyles: undefined,
}

export function PlaylistEventsSection({ containerStyles, gtmType, searchQuery, searchFilters, wrapInCarousel, size }) {
  const [filters, setFilters] = useState()

  const {
    selectData,
    searchQueryResult: { isFetching, isError },
  } = useSearchQuery(
    {
      size,
      ...searchQuery,
      ...filters,
    },
    {
      canUseBounds: false,
    },
  )

  const events = selectData(({ events }) => events)

  if (isError) return null

  return (
    <>
      <SearchFilters filters={searchFilters} onChange={setFilters} />

      {isFetching ? (
        <PlaylistEventsSectionSkeleton containerStyles={containerStyles} />
      ) : (
        <EventsSection
          containerStyles={containerStyles}
          events={events}
          gtmType={gtmType}
          wrapInCarousel={wrapInCarousel}
          render={(event) => (
            <BasicSearchEventCard
              key={event.id}
              gtmType={gtmType}
              height='341px'
              event={event}
              hideSeatsLeft
              hideNextAvailableDate
            />
          )}
          {...eventsSectionProps}
        />
      )}
    </>
  )
}

PlaylistEventsSection.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  gtmType: PropTypes.string,
  wrapInCarousel: PropTypes.bool,
  searchFilters: filtersPropTypes,
  searchQuery: PropTypes.shape({}).isRequired,
  size: PropTypes.number,
}

PlaylistEventsSection.defaultProps = {
  containerStyles: undefined,
  gtmType: undefined,
  wrapInCarousel: false,
  searchFilters: undefined,
  size: undefined,
}
