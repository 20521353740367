import { cloudFlareWorkersApi } from 'apis'

export async function getTripAdvisorUser(id, query) {
  const { data } = await cloudFlareWorkersApi.get(`/tar/location`, {
    params: {
      id,
      ...query,
    },
  })

  return data
}
