// libs
import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes' // components
import NextHead from 'next/head'
import { NextImage } from 'components/shared/NextImage'
import { HeroSection as ComponentLibraryHeroSection } from '@vizeat/components/es6/components/HeroSection'
import { ButtonLink } from 'components/shared/ButtonLink'
// helpers
import { scaleCropFile, mainImagesMeta, urlImageMeta } from 'helpers/ImageManager'

// This component is just a wrapper around the visual base existing in the component
// library. It exist solely to add elements in the <head> and build correct image
// URLs if coming for example from a Prismic page
export function HeroSection({
  alt,
  backgroundImage,
  buttonText,
  children,
  onClick,
  shouldOffsetHeader,
  smart,
  subtitle,
  text,
  title,
  to,
  useImageAsMeta,
  videoProps,
  ...props
}) {
  const { url, blurDataURL, placeholder, meta } = useMemo(() => {
    const isUploadcareId = !isNaN(backgroundImage)
    return {
      url: backgroundImage,
      blurDataURL: scaleCropFile({ fileId: backgroundImage, size: `4x3` }),
      placeholder: isUploadcareId ? 'blur' : 'empty',
      meta: isUploadcareId
        ? mainImagesMeta({ id: backgroundImage })
        : urlImageMeta(backgroundImage, { width: 2000, height: 700 }),
    }
  }, [backgroundImage])

  return (
    <>
      {useImageAsMeta && (
        <NextHead>
          {meta.map((props, key) => (
            <meta key={key} {...props} />
          ))}
        </NextHead>
      )}

      <ComponentLibraryHeroSection {...(shouldOffsetHeader && { mb: '-72px', pt: '72px', top: '-72px' })} {...props}>
        <NextImage
          src={url}
          smart={smart}
          alt={alt}
          fill
          aspectRatio={Math.ceil(2000 / 700)}
          placeholder={placeholder}
          blurDataURL={blurDataURL}
          priority
          imageStyle='filter: brightness(0.8) !important; object-fit: cover;'
        />
        <ComponentLibraryHeroSection.Video videoProps={videoProps} />
        <ComponentLibraryHeroSection.Content width={{ default: 'auto', desktop: '60%' }}>
          <ComponentLibraryHeroSection.Title>{title}</ComponentLibraryHeroSection.Title>
          <ComponentLibraryHeroSection.Subtitle>{subtitle}</ComponentLibraryHeroSection.Subtitle>
          <ComponentLibraryHeroSection.Text>{text}</ComponentLibraryHeroSection.Text>
          {buttonText && (
            <ButtonLink rounded large color='superPaleYellow' onClick={onClick} href={to}>
              {buttonText}
            </ButtonLink>
          )}
        </ComponentLibraryHeroSection.Content>
        {children}
      </ComponentLibraryHeroSection>
    </>
  )
}

HeroSection.propTypes = {
  alt: PropTypes.string,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  shouldOffsetHeader: PropTypes.bool,
  smart: PropTypes.bool,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  useImageAsMeta: PropTypes.bool,
  videoProps: PropTypes.shape({ url: PropTypes.string.isRequired }),
}

HeroSection.defaultProps = {
  alt: '',
  buttonText: undefined,
  children: undefined,
  onClick: undefined,
  shouldOffsetHeader: true,
  smart: false,
  subtitle: undefined,
  text: undefined,
  title: undefined,
  to: undefined,
  useImageAsMeta: false,
  videoProps: undefined,
}
